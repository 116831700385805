//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-852:_5856,_1016,_5696,_6784,_5284,_9350,_1546,_2238;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-852')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-852', "_5856,_1016,_5696,_6784,_5284,_9350,_1546,_2238");
        }
      }catch (ex) {
        console.error(ex);
      }