import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';
import { bigcommerceAppClientId } from '../_common/constants.js';

globalThis.Convermax.isLoggedIn = window.document.querySelector(
  '.navUser .navUser-action[href="/login.php?action=logout"]',
);

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!window.Convermax?.isLoggedIn) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });

  return groupId || '';
}

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-spark'],
  getSearchRequestDefaults: (pageType) => ({ extra: { pageType } }),
  SearchRequestDefaults: {
    ...bigcommerceSparkDefaults.SearchRequestDefaults,
    extra: { customerGroupId },
  },
  autocomplete: {
    requestDefaults: { extra: { customerGroupId } },
  },
  fitmentSearch: {
    ...bigcommerceSparkDefaults.fitmentSearch,
    expectResponseRedirect: true,
  },
};
